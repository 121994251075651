<script setup>

const {onMouseUp, onMouseMove} = useMouseEvents();
const { onDebouncedWindowEvent, onWindowEvent } = useWindowEvents();

const props = defineProps({
  scrolledElement: Object
});

const emits = defineEmits(['scroll','visibilityChange','scrollEnd']);

const elementToScroll = ref();

let handleDragOffsetX = 0;

const refTrack = ref();
const refHandle = ref();
const offsetLeft = ref(0);
const dragging = ref(false);

const handleLength = ref(0);
const trackLength = ref(0);
const visible = ref(true);

const calculateRatio = () => {
  const width = elementToScroll.value.getInnerWidth();
  const scrollW = elementToScroll.value.scrollWidth;
  if (!scrollW) return 0;
  return width / scrollW;
}

const calculateRevRatio = () => {
  const trackRect = refTrack.value.getBoundingClientRect();
  const handleRect = refHandle.value.getBoundingClientRect();
  const maxTrackSize = trackRect.width - handleRect.width;
  const scrollW = elementToScroll.value.scrollWidth;
  const width = elementToScroll.value.getInnerWidth();
  return maxTrackSize / (scrollW - width);
}

const scrollTo = (left, barOnly = false) => {
  const trackRect = refTrack.value.getBoundingClientRect();
  const handleRect = refHandle.value.getBoundingClientRect();
  const maxTrackSize = trackRect.width - handleRect.width;

  left = Math.min(Math.max(left, 0), maxTrackSize);

  offsetLeft.value = left;

  const ratio = calculateRevRatio();

  if(barOnly) return;
  elementToScroll.value.scrollTo(left / ratio, 0)
}

const startDrag = (event) => {
  const {x} = refHandle.value.getBoundingClientRect();
  handleDragOffsetX = (event.pageX || event.touches[0].pageX) - x;
  dragging.value = true;
}
const drag = (event) => {
  if (!dragging.value) return;
  const pageX = event.pageX || event.touches[0].pageX;

  const trackRect = refTrack.value.getBoundingClientRect();
  let left = pageX - trackRect.x - handleDragOffsetX;

  scrollTo(left);
}

const init = () => {
  if (!props.scrolledElement) return;
  elementToScroll.value = props.scrolledElement?.getBoundingClientRect ? props.scrolledElement : props.scrolledElement.$el;

  nextTick(() => {
    const ratio = calculateRatio();
    const {width} = elementToScroll.value.getBoundingClientRect();

    const lastTrackLength = trackLength.value;
    const lastOffsetLeft = offsetLeft.value;
    const handlePosition = lastOffsetLeft / lastTrackLength;
    trackLength.value = refTrack.value.getBoundingClientRect().width;
    handleLength.value = Math.round(trackLength.value * ratio);

    offsetLeft.value = trackLength.value * handlePosition;
    visible.value = width < elementToScroll.value.scrollWidth;
    emits('visibilityChange',visible.value);

  });
}
const attachEvents = () => {
  const hasEventsAttached = elementToScroll.value.connected || false;
  if (hasEventsAttached) return;
  elementToScroll.value.connected = true;
  const dragEvent = () => {
    elementToScroll.value.addEventListener("touchstart", (event) => {
      elementToScroll.value.dragging = true;
      elementToScroll.value.startX = event.touches[0].pageX;
      elementToScroll.value.startScrollLeft = elementToScroll.value.scrollLeft;
    }, {passive: true})

    elementToScroll.value.addEventListener("touchmove", (event) => {
      if (!elementToScroll.value.dragging) return;
      const ratio = calculateRatio();
      const left = elementToScroll.value.startScrollLeft + (elementToScroll.value.startX - event.touches[0].pageX);
      const {width: maxWidth} = refTrack.value.getBoundingClientRect();

      elementToScroll.value.scrollLeft = left;
      offsetLeft.value = Math.min(Math.max(left * ratio, 0), maxWidth - handleLength.value);
    });
  }

  const resizeEvent = () => {
    const ob = new MutationObserver(() => {
      init();
    })

    ob.observe(elementToScroll.value, {
      attributes: true,
      childList: true,
      subtree: false
    });
  }

  const scrollEvent = () => {
    elementToScroll.value.addEventListener('scroll', () => {
      if (!dragging.value) {
        const ratio = calculateRevRatio();
        const left = elementToScroll.value.scrollLeft * ratio;
        scrollTo(left, true)
      }
    })
  }

  resizeEvent();
  dragEvent();
  scrollEvent();
}

onMouseUp(() => {
  if(elementToScroll.value && dragging.value) {
    elementToScroll.value.dragging = false;
    emits('scrollEnd');
  }
  dragging.value = false
});

onMouseMove(drag);

onWindowEvent('resize', () => init());

watch(
  () => props.scrolledElement,
  (element) => {
    if (element) {
      init();
      attachEvents();
    }
  },
  { once: true}
);

onMounted(() => {
  init();
})

</script>

<template>
  <div ref="refTrack" class="relative w-full h-8 overflow-hidden" :class="[{'opacity-0 -mr-8':!visible}]">
    <div class="absolute left-0 h-0.5 my-4 w-full bg-gray-200"></div>
    <div ref="refHandle"
         class="group/scrollbar absolute top-0 h-8"
         :style="[{left: offsetLeft + 'px'},{'width': handleLength + 'px'}]"
         @mousedown.passive="startDrag"
         @touchstart.passive="startDrag">
      <div class="h-0.5 w-full my-4 bg-azure rounded-full group-hover/scrollbar:my-[13px] group-hover/scrollbar:h-2 transition-all"></div>
    </div>
  </div>
</template>
